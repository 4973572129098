// 引入封装的第一层axios接口
import request from "utils/req";

// 首页资源
export const getResource = (params) => {
    return request({
        url: '/api/regionIndex/getRegionIndexPage',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 资源详情
export const getResourceDetail = (params) => {
    return request({
        url: '/api/regionIndex/getById',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 资源目录
export const getResourceCatalogue = (params) => {
    return request({
        url: '/api/regionIndex/getContents',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 保存学习记录
export const saveResourceLearn = (params) => {
    return request({
        url: '/api/resourcesLearn/save',
        method: 'post',
        data: {
            ...params
        }
    })
}

// 查询学习记录
export const getResourceLearn = (params) => {
    return request({
        url: '/api/resourcesLearn/getList',
        method: 'get',
        params: {
            ...params
        }
    })
}
