<template>
  <div class="box">
    <div class="fs_18 m_b_10">
      <el-breadcrumb separator-class="el-icon-arrow-right" v-if="type == 1">
        <el-breadcrumb-item
          :to="{
            name: 'regionResource',
            params: { classify: '1555104854509219841', name: '劳动教育' },
          }"
        >
          劳动教育
        </el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            name: 'practiceAssistant',
            query: { type: 'achievement' },
            params: { current, selected },
          }"
        >
          成果展示
        </el-breadcrumb-item>
        <el-breadcrumb-item>活动作品</el-breadcrumb-item>
      </el-breadcrumb>
      <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
        <el-breadcrumb-item
          :to="{
            name: 'regionResource',
            params: { classify: '1555104809068130306', name: '美育' },
          }"
        >
          美育
        </el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            name: 'regionResource',
            params: {
              classify: '1555104809068130306',
              name: '美育',
              current,
            },
            query: { aestheticEducation: true },
          }"
        >
          学生作品展示
        </el-breadcrumb-item>
        <el-breadcrumb-item>活动作品</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content-box flex_row">
      <div class="resource-box m_r_10">
        <img
          :src="selectedResource.resourcesUrl"
          alt=""
          v-if="selectedResource.type == 4"
        />
        <video
          :src="selectedResource.resourcesUrl"
          v-else-if="selectedResource.type == 3"
          controls
        ></video>
        <div
          class="btn-box bg_fff"
          v-else-if="selectedResource.type == 1"
          :style="`background: url(${coverUrl}) 100% 100% / cover;background-position:center;`"
        >
          <el-button class="btn" type="primary" @click="preview_click">
            点击预览
          </el-button>
        </div>
      </div>
      <div class="resource-list-box bg_fff br_5">
        <div class="fs_18 fw_bold p_in_05">作品资料</div>
        <div
          class="fs_16 p_in_05 pointer"
          :class="{ selected: item.id == selectedResource.id }"
          v-for="item in resourceList"
          :key="item.id"
          @click="select_click(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getResourceDetail } from "../../../../../../api/resourceCenter/region/index";
export default {
  name: "ActivityDetail",
  data() {
    return {
      resourceList: [],
      selectedResource: {},
      type: 1,
      coverUrl: null,
      current: null,
      selected: null,
    };
  },
  async mounted() {
    this.type = this.$route.query.type;
    this.current = this.$route.query.current;
    this.selected = this.$route.query.selected;
    await getResourceDetail({ id: this.$route.query.id }).then((res) => {
      this.coverUrl = res.data.coverUrl;
      let data = [
        {
          id: res.data.id,
          name: res.data.name,
          resourcesUrl: res.data.resourcesUrl,
          type: res.data.type,
        },
      ].concat(res.data.enclosureList);
      this.resourceList = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          resourcesUrl:
            item.type == 1
              ? `http://139.155.146.142:8012/onlinePreview?url=${encodeURIComponent(
                  btoa(item.resourcesUrl)
                )}`
              : item.resourcesUrl,
          type: item.type,
          coverUrl: item.coverUrl,
        };
      });
      this.selectedResource = this.resourceList[0];
    });
  },
  methods: {
    select_click(data) {
      this.selectedResource = data;
    },
    preview_click() {
      window.open(this.selectedResource.resourcesUrl, "__blank");
    },
  },
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: 20px auto;
}
.content-box {
  justify-content: space-between;
}
.resource-box {
  /* width: 800px; */
  width: 100%;
}
.resource-list-box {
  width: 300px;
}

.selected {
  background-color: #498cf01a;
}
img,
video,
iframe {
  width: 100%;
}
iframe {
  height: 1200px;
}
/* img, */
video {
  max-height: 560px;
}

.btn-box {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 500px;
}
.btn-box::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
}
.btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
</style>
