var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"fs_18 m_b_10"},[(_vm.type == 1)?_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{
          name: 'regionResource',
          params: { classify: '1555104854509219841', name: '劳动教育' },
        }}},[_vm._v(" 劳动教育 ")]),_c('el-breadcrumb-item',{attrs:{"to":{
          name: 'practiceAssistant',
          query: { type: 'achievement' },
          params: { current: _vm.current, selected: _vm.selected },
        }}},[_vm._v(" 成果展示 ")]),_c('el-breadcrumb-item',[_vm._v("活动作品")])],1):_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{
          name: 'regionResource',
          params: { classify: '1555104809068130306', name: '美育' },
        }}},[_vm._v(" 美育 ")]),_c('el-breadcrumb-item',{attrs:{"to":{
          name: 'regionResource',
          params: {
            classify: '1555104809068130306',
            name: '美育',
            current: _vm.current,
          },
          query: { aestheticEducation: true },
        }}},[_vm._v(" 学生作品展示 ")]),_c('el-breadcrumb-item',[_vm._v("活动作品")])],1)],1),_c('div',{staticClass:"content-box flex_row"},[_c('div',{staticClass:"resource-box m_r_10"},[(_vm.selectedResource.type == 4)?_c('img',{attrs:{"src":_vm.selectedResource.resourcesUrl,"alt":""}}):(_vm.selectedResource.type == 3)?_c('video',{attrs:{"src":_vm.selectedResource.resourcesUrl,"controls":""}}):(_vm.selectedResource.type == 1)?_c('div',{staticClass:"btn-box bg_fff",style:(`background: url(${_vm.coverUrl}) 100% 100% / cover;background-position:center;`)},[_c('el-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.preview_click}},[_vm._v(" 点击预览 ")])],1):_vm._e()]),_c('div',{staticClass:"resource-list-box bg_fff br_5"},[_c('div',{staticClass:"fs_18 fw_bold p_in_05"},[_vm._v("作品资料")]),_vm._l((_vm.resourceList),function(item){return _c('div',{key:item.id,staticClass:"fs_16 p_in_05 pointer",class:{ selected: item.id == _vm.selectedResource.id },on:{"click":function($event){return _vm.select_click(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }